import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { environment } from '@env/environment';

declare var H: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('mapContainer') mapContainer: ElementRef;

  map: any;

  private _lastAddedMarker = 0;
  private markers: any = {};

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initializeMap();
  }

  private initializeMap() {
    const platform = new H.service.Platform({
      apikey: environment.here_maps_key,
    });
    const defaultLayers = platform.createDefaultLayers();
    this.map = new H.Map(this.mapContainer.nativeElement, defaultLayers.vector.normal.map, {
      zoom: 13,
      center: { lat: 25.2048, lng: 55.2708 },
      padding: { top: 50, left: 50, bottom: 50, right: 50 },
    });
    const mapEvents = new H.mapevents.MapEvents(this.map);
    const behavior = new H.mapevents.Behavior(mapEvents);
    H.ui.UI.createDefault(this.map, defaultLayers);
  }

  /**
   * add a new marker to the map and return that marker id
   * @param coords lat lng on which the marker will be added
   * @returns the marker id
   */
  addMarker(coords: any): number {
    return this._addMarker(this.map, coords);
  }

  setViewBounds(markers: any) {
    let group = new H.map.Group();
    if (markers !== null && markers.length > 0) {
      for (let marker of markers) {
        let element = new H.map.Marker(marker);
        group.addObject(element);
      }
      this.map.addObject(group);
      this.map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      });
    }
  }

  removeMarker(id: number) {
    if (this.markers[id]) {
      this.map.removeObject(this.markers[id]);
      delete this.markers[id];
    }
  }

  /**
   * Add marker to Ahoy map
   * @param map here map which the marker should be added to
   * @param coords coordinates where the marker should be added
   * @returns index number represents new marker index
   */
  private _addMarker(map: any, coords: any): number {
    // create the marker object and set its coordinates
    const marker = new H.map.Marker(coords, {
      volatility: true,
    });
    // add the marker to the map
    map.addObject(marker);
    // recenter the map around the marker
    map.setCenter(coords);
    // add the marker to current markers
    this.markers[++this._lastAddedMarker] = marker;
    return this._lastAddedMarker;
  }
}
