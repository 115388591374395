import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { error } from 'protractor';
import { AuthenticationService } from '@app/auth';
import { GlobalEventsService } from '@app/global-events.service';

@Injectable()
export class TermsNotAcceptedInterceptor implements HttpInterceptor {
  constructor(private globalEventsService: GlobalEventsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request);
    // .pipe(
    //   catchError((err: any) => {
    //     if (err.status === 451) {
    //       this.globalEventsService.onPromotToAcceptTerms.emit();
    //     }
    //     return of(err);
    //   })
    // );
  }
}
