import { Component, Input, OnInit } from '@angular/core';
import { GlobalEventsService } from '@app/global-events.service';
import { QuickActionsService } from '@app/quick-action/quick-actions.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-customer-cant-pay-action',
  templateUrl: './customer-cant-pay-action.component.html',
  styleUrls: ['./customer-cant-pay-action.component.scss'],
})
export class CustomerCantPayActionComponent implements OnInit {
  isLoading = false;

  @Input() order: any;

  action: any;

  constructor(private quickActionsService: QuickActionsService, private globalEventsService: GlobalEventsService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const activeActions: any[] = this.order.quickActions?.filter((action: any) => {
      return action.statusId === 1;
    });
    this.action = activeActions && activeActions.length > 0 ? activeActions[0] : null;
  }

  rejectRequest() {
    this.respond(34);
  }

  returnOrder() {
    this.respond(32);
  }

  deliverLater() {
    this.respond(33);
  }

  removeAmount() {
    this.respond(31);
  }

  private respond(responseType: number) {
    const body = {
      quickActionId: this.action.quickActionId,
      unableToPayResponseTypeId: responseType,
    };
    this.isLoading = true;
    this.quickActionsService
      .customerUnableToPay(body)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.globalEventsService.onQuickActionResolved.emit(this.action);
        })
      )
      .subscribe(
        (res: any) => {},
        (err: any) => {}
      );
  }
}
