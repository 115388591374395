import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth';

const routes = {
  supportConfigUrl: () => `/org/configuration/support-info/merchant`,
};
@Injectable({ providedIn: 'root' })
export class SupportConfigService {
  constructor(private httpClient: HttpClient, private credentialsService: CredentialsService) {}

  getSupportConfig() {
    return this.httpClient.get(routes.supportConfigUrl(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }
}
