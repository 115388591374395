import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountriesService } from '@app/@core/services/countries.service';
import { StorageKey } from '@app/@core/services/storage/storage-key';
import { StorageService } from '@app/@core/services/storage/storage.service';
import { fade, slide, swipe } from '@app/animations';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { DomainInfo } from '@app/models/domain-info.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  animations: [fade, slide, swipe],
})
export class CreateAccountComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  env = environment;
  isLoading = false;
  phones: any[] = [];
  companyLogoUrl: string = '/assets/icons/iot-logo.png';
  isCompanyLoading: boolean = true;

  accountData: any;

  step = 1;
  country = CountriesService.countrySettings.country;

  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    this.setCompanyDetails();
  }

  setCompanyDetails() {
    const domainInfo: DomainInfo = this.storageService.getData(StorageKey.domainInfo);
    if (domainInfo?.isValidDomain) {
      this.companyLogoUrl = domainInfo.companyLogoUrl || this.companyLogoUrl;
      this.env.solutionOwner = domainInfo.companyName || this.env.solutionOwner;
    }
    this.isCompanyLoading = false;
  }

  back(step: number) {
    this.step = step;
  }

  proceed(data: any) {
    this.step = 2;
    this.accountData = data;
  }
}
