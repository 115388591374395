import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

export interface Feedback {
  companyId?: number;
  companyName?: string;
  title?: string;
  feedback?: string;
  rate?: number;
  country?: string;
  applicationName?: string;
  createdDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  feedbackCollection: AngularFirestoreCollection<Feedback>;

  constructor(public angularFirestore: AngularFirestore) {
    // return the collection from firebase firestore
    this.feedbackCollection = this.angularFirestore.collection('feedbacks', (ref) =>
      ref.orderBy('createdDate', 'desc')
    );
  }

  // add feedback to firestore
  addFeedback(feedback: Feedback) {
    this.feedbackCollection.add(feedback);
  }
}
