import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { AuthenticationGuard } from './authentication.guard';
import { LoginComponent } from './login.component';
import { CreateAccountComponent } from './register/create-account/create-account.component';
import { CompanyUnderApprovalComponent } from './register/create-company/company-under-approval/company-under-approval.component';
import { CreateCompanyComponent } from './register/create-company/create-company.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: extract('Login') } },

  { path: 'reset-password', component: ResetPasswordComponent },

  { path: 'register', component: CreateAccountComponent, data: { title: extract('Create Account') } },
  {
    path: 'createCompany',
    component: CreateCompanyComponent,
    data: { title: extract('Create Your Company') },
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'companyReview',
    component: CompanyUnderApprovalComponent,
    data: { title: extract('Company under review') },
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'companyReview/:status',
    component: CompanyUnderApprovalComponent,
    data: { title: extract('Company under review') },
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
