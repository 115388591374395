/**
 * global types, enums, and interfaces  can be declared here
 */

export interface IOrderProps {
  ahoyDeliveryPrice: number;
  area: string;
  building: string;
  bulkOrderId: number;
  checkoutPaymentTypeId: number;
  companyId: number;
  companyOrderTrackId: string;
  customerAddress?: string;
  customerAddressNote?: string;
  customerAddressTypeId: number;
  customerEmail?: string;
  customerLocationLatitude: number;
  customerLocationLongitude: number;
  customerName: string;
  customerPhone: string;
  deliveryServiceTypeId: number;
  deliveryTime: number;
  dontContactCustomer: number;
  driverDetail?: any;
  droppedAtTag: string;
  etOfPickup: number;
  floor: string;
  isAhoyBox: boolean;
  isBulkOrder: boolean;
  isCardPayment: false;
  isCashPayment: boolean;
  isDeliverInPerson: boolean;
  isExpressOrder: boolean;
  isReOrdered: boolean;
  isReadyForPickup: boolean;
  isScheduledOrder: boolean;
  isSubscriptionOrder: boolean;
  isVirtualStoreOrder: boolean;
  largeBoxTempMax: number;
  largeBoxTempMin: number;
  liveTrackingUrl: string;
  maxDeliveryTime: Date;
  maxPickupTime: Date;
  midBoxTempMax: number;
  midBoxTempMin: number;
  newOrderId: number;
  oldOrderId: number;
  orderId: number;
  orderLargeBoxQuantity: number;
  orderMidBoxQuantity: number;
  orderSmallBoxQuantity: number;
  orderStatusId: number;
  orderStatusName: string;
  orderTime: Date;
  orderbyAccountId: number;
  paymentCollected: number;
  pdfUrl: string;
  pickupLocationId: number;
  pickupLocationLatitude: number;
  pickupLocationLongitude: number;
  pickupLocationName: string;
  proofOfDelivery: any;
  qrCodeUrl: string;
  qrCodes: [];
  quickActions: [];
  requireProofOfDelivery: boolean;
  smallBoxTempMax: number;
  smallBoxTempMin: number;
  temperatureTypeId: number;
  trackingHistory: StatusTracker[];
  unit: string;
}

export enum progressBarMode {
  DETERMINATE = 'determinate',
  INDETERMINATE = 'indeterminate',
  BUFFER = 'buffer',
  QUERY = 'query',
}
export type StatusTracker = {
  description?: string;
  statusId?: number;
  updatedTime?: number;
};

/**
 * defines order status type by id
 */
export enum statusIdTypes {
  NEW = 1,
  ASSIGNED_TO_NEW_JOB = 12,
  IN_PICK_UP_TASK = 21,
  OUT_FOR_DELIVERY = 22,
  OUT_FOR_RETURN = 23,
  DELIVERING_NOW = 221,
  RETURNED_TO_MERCHANT = 231,
  DELIVERED = 3,
  COMPLETED_WITH_RETURN_POLICY = 31,
  FAILED_TO_PICK_FROM_BOUNTY_DRIVER = 412,
  FAILED_TO_PICK_FROM_CUSTOMER = 413,
  FAILED_TO_DELIVER_TO_COMPANY = 421,
  FAILED_TO_PICK_UP_FROM_COMPANY = 411,
  FAILED_DELIVERY_TO_CUSTOMER = 423,
  CANCELLED = 5,
}

/**
 * defines maps to statusId types
 */
export enum orderStatusType {
  NEW = 'New',
  ASSIGNED_TO_NEW_JOB = 'Assigned to job',
  IN_PICK_UP_TASK = 'In Pickup Task',
  OUT_FOR_DELIVERY = 'Out For Delivery',
  DELIVERING_NOW = 'Delivering Now',
  DELIVERED = 'Delivered',
  OUT_FOR_RETURN = 'Out For Return',
  FAILED_TO_PICK_UP_FROM_COMPANY = 'Failed To Pickup From Company',
  FAILED_DELIVERY_TO_CUSTOMER = 'Failed Delivery To Customer',
  CANCELLED = 'Cancelled',
}
/**
 * defined the order status color tracker
 */
export enum orderColorDescription {
  DEFAULT = 'primary',
  FAILED_OR_CANCELLED = 'warn',
}

/**
 * Defined the Nodes of the orderstatus details - have color, mode and name of the bar
 */
export interface INodesEntry {
  color?: string;
  mode?: string;
  name?: string;
  value: string;
}
/** defined progres of the bar, for now we have  full and empty */
export enum progress {
  FULL = '100',
  EMPTY = '0',
}
