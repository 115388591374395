import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
})
export class PromotionComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  ahoyFriday() {
    this.router.navigateByUrl('/help');
  }
}
