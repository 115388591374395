<div class="form-div custom-card">
  <h3>Create your company</h3>
  <p class="sub-label">Together.. we are changing movement</p>
  <form (ngSubmit)="continue()" [formGroup]="companyForm" novalidate>
    <div class="login-error" [hidden]="!error || isLoading" translate>
      <!-- Username or password incorrect. -->
    </div>
    <br />
    <div class="login-fields" fxLayout="column">
      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Your interest</mat-label>
        <mat-select formControlName="interest" multiple>
          <mat-select-trigger>
            {{ companyForm.controls.interest.value ? companyForm.controls.interest.value[0] : '' }}
            <span *ngIf="companyForm.controls.interest.value?.length > 1">
              (+{{ companyForm.controls.interest.value.length - 1 }}
              {{ companyForm.controls.interest.value?.length === 2 ? 'other' : 'others' }})
            </span>
          </mat-select-trigger>
          <mat-option value="Temperature controlled delivery">Temperature controlled delivery</mat-option>
          <mat-option value="Dry delivery">Dry delivery</mat-option>
          <mat-option value="Payment Solutions">Payment Solutions</mat-option>
          <mat-option value="Integrations">Integrations</mat-option>
          <mat-option value="Returns">Returns</mat-option>
          <mat-option value="Fleet management">Fleet management</mat-option>
          <mat-option value="I am a service provider and want to see how we can work together"
            >I am a service provider and want to see how we can work together</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role" name="role">
          <mat-option value="I am the Owner/partner/authorized person"
            >I am the Owner/partner/authorized person
          </mat-option>
          <mat-option value="I am the billing/purchasing pro in here"
            >I am the billing/purchasing pro in here
          </mat-option>
          <mat-option value="I am the just applying on behalf of my company"
            >I am just applying on behalf of my company
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Sector</mat-label>
        <mat-select formControlName="sector" name="sector">
          <mat-option *ngFor="let sector of sectors" [value]="sector">{{ sector }}</mat-option>
          <mat-option value="other">Other</mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <button mat-raised-button color="primary" type="submit" [disabled]="companyForm.invalid || isLoading">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <span translate>Proceed</span>
      </button>
    </div>
  </form>
</div>
