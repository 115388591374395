<div class="container">
  <form [formGroup]="form">
    <div class="row mb-2">
      <div class="col-12">
        <mat-checkbox class="custom-frame" formControlName="passwordMin"
          >Password should be 8 characters or more</mat-checkbox
        >
      </div>
    </div>
    <p class="title">and should include at least 3 of the following:</p>
    <div class="row password-criteria">
      <div class="col-3">
        <p class="number">1</p>
        <p class="description">Number</p>
        <mat-checkbox class="custom-frame custom-position" formControlName="passwordDigit"> </mat-checkbox>
      </div>
      <div class="col-3">
        <p class="number">1</p>
        <p class="description">UPPERCASE</p>
        <mat-checkbox class="custom-frame custom-position" formControlName="passwordUpperCase"> </mat-checkbox>
      </div>
      <div class="col-3">
        <p class="number">1</p>
        <p class="description">LOWERCASE</p>
        <mat-checkbox class="custom-frame custom-position" formControlName="passwordLowerCase"> </mat-checkbox>
      </div>
      <div class="col-3">
        <p class="number">1</p>
        <p class="description">$pecial Ch@racter</p>
        <mat-checkbox class="custom-frame custom-position" formControlName="passwordSpecial"> </mat-checkbox>
      </div>
    </div>
  </form>
</div>
