<div class="m-container">
  <div class="row">
    <div class="col-lg-6">
      <img [src]="image" />
    </div>
    <div class="col-lg-6">
      <div class="content">
        <p>
          {{ smallText }}<br />
          <b>{{ boldText }}</b>
        </p>
      </div>
    </div>
  </div>
</div>
