import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { APIResponseStatus } from '../api-response-status.enum';
import { BaseStore } from './store-pattern/base-store.service';
export interface ICity {
  id: number;
  name: string;
}

export interface ICitiesResponse {
  cities: ICity[];
  countryId: number;
}
@Injectable({
  providedIn: 'root',
})
export class CitiesService extends BaseStore<ICitiesResponse[]> {
  stores: { [key: string]: BaseStore<ICitiesResponse> } = {};

  constructor(httpClient: HttpClient, credentialsService: CredentialsService) {
    super(httpClient, credentialsService, {
      baseUrl: '/org/merchant/PickupLocation',
      getUrl: '/cities',
    });
  }

  getByCountryCode(countryCode: string): Observable<ICity[]> {
    return this.storeByCountryCode(countryCode)
      .getStoredData()
      .pipe(map((c) => c?.cities ?? []));
  }

  newStoreByCountryCode(countryCode: string): BaseStore<ICitiesResponse> {
    return new BaseStore<ICitiesResponse>(this.httpClient, this.credentialsService, {
      baseUrl: this.baseUrl,
      getUrl: `/${countryCode}${this.options?.getUrl}`,
      onSuccess: (data: ICitiesResponse) => {
        (this.data ??= []).push(data);
      },
    });
  }

  storeByCountryCode(countryCode: string): BaseStore<ICitiesResponse> {
    const store = (this.stores[`countryIdStore${countryCode}`] ??= this.newStoreByCountryCode(countryCode));
    store.init();
    return store;
  }
}
