<div fxFlex>
  <mat-sidenav-container fxFill>
    <mat-sidenav
      #sidenav
      class="sidenav-bar"
      [mode]="isMobile ? 'over' : 'side'"
      [opened]="!isMobile"
      [disableClose]="!isMobile"
      ngClass.gt-sm="has-border"
    >
      <mat-toolbar color="light" class="top-left-bar">
        <img [src]="companyLogoUrl" class="side-menu-logo" />
        <span class="side-menu-title" translate>{{ env.appCommercialName }}</span>
        <div fxFlex></div>
      </mat-toolbar>

      <!-- Example split danger button -->
      <div class="order-creation-container">
        <mat-toolbar color="light" class="top-left-bar orders-creation">
          <ng-container class="quick-actions">
            <a (click)="order()">CREATE ORDER </a>
          </ng-container>
          <ng-container>
            <a class="add-order-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <mat-icon class="order-create-options">keyboard_arrow_down</mat-icon>
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="order()">Order</a>
              <button
                mat-button
                class="dropdown-item bulk-btn"
                (click)="bulkOrder()"
                [ngClass]="{ 'disabled-text': isBulkCreationDisabled }"
                [disabled]="isBulkCreationDisabled"
              >
                Bulk Order
              </button>
              <a class="dropdown-item" *ngIf="env.featuresAvailability.subscription" (click)="subscription()"
                >Subscription</a
              >
            </div>
          </ng-container>
        </mat-toolbar>
      </div>

      <nav>
        <mat-list class="side-menu-options">
          <ng-container *ngFor="let item of menuOptions">
            <a
              @slide
              mat-list-item
              [routerLink]="item.link"
              routerLinkActive="active"
              (click)="isMobile && sidenav.close()"
              *ngIf="item.enabled"
            >
              <img [src]="item.icon" />
              <span translate>{{ item.label }}</span>
              <span class="{{ item.badge == 'NEW' ? 'badge-new' : 'badge' }}" translate>{{ item.badge }}</span>
            </a>
          </ng-container>
        </mat-list>
        <div class="side-menu-footer">
          <button mat-button color="primary" (click)="submitFeedbackDialog()">Submit Feedback</button>
          <p>
            © All copyrights reserved for {{ env.solutionOwner }} {{ year }} <br />
            Version {{ version }}
          </p>
        </div>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="light" class="content-toolbar mat-elevation-z0 top-bar">
        <button class="menu-button" mat-icon-button (click)="menuBtnClicked()">
          <mat-icon>menu</mat-icon>
        </button>
        <span>{{ title }}</span>
        <div fxFlex></div>

        <div>
          <button mat-icon-button [matMenuTriggerFor]="userMenu" translate>
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #userMenu="matMenu">
            <mat-list>
              <mat-list-item>
                <p class="date">
                  {{ date | date: 'shortTime' }} <br />
                  {{ timezone }}
                </p>
              </mat-list-item>
              <mat-list-item class="username-option">
                {{ username }}
              </mat-list-item>
              <mat-divider></mat-divider>
              <button mat-menu-item routerLink="/settings">Settings</button>
              <!-- <mat-divider></mat-divider>
              <button mat-menu-item (click)="changeTheme()">
                {{ darkMode === false ? 'Dark Mode' : 'Light Mode' }}
              </button> -->
              <mat-divider></mat-divider>
            </mat-list>
            <button mat-menu-item (click)="logout()" translate>Logout</button>
          </mat-menu>
        </div>
        <!-- <app-language-selector icon="true"></app-language-selector> -->
      </mat-toolbar>
      <div
        class="trial-alert"
        *ngIf="isInTrialPeriod"
        [class]="sidenav.opened ? 'shrink-top-bar-view' : 'stretch-top-bar-view'"
      >
        <p class="trial-msg">
          On Trial: Your trial is active, Your trial ends at {{ trialEndDate.getTime() | date: 'hh:mm a' }} on “{{
            trialEndDate.getTime() | date: 'dd MMM, YYYY'
          }}”.
        </p>
        <div class="promo-container">
          <p class="enroll-promo">Enroll to a plan, to access more options</p>
          <a href="/settings" class="upgrade">UPGRADE NOW</a>
        </div>
      </div>
      <div class="content-container" [class]="isInTrialPeriod ? 'content-container-in-trial' : ''">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div class="side-menu-quick-actions">
    <app-actions-list></app-actions-list>
  </div>
</div>
