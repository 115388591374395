<div>
  <div class="row bar">
    <div class="col-sm-7">
      <div class="row bar-body">
        <div class="col-sm-1">
          <img src="/assets/images/actions/payment.svg" />
        </div>
        <div class="col-sm-8 action-body">
          <h3>Customer Says they cannot pay.</h3>
          <p class="attention-required-p">
            <span class="red-span">Action is Required!</span>
            for order
            <span class="bold-span">(REF: #{{ order?.companyOrderTrackId }}, id: {{ order?.orderId }})</span>
          </p>
        </div>
        <div class="col-sm-3 right-part">
          <p class="small-title">expires at</p>
          <p>{{ action?.expiryTime | date: 'h:mm a' }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-5 btns">
      <div class="btn-col">
        <div
          class="btn-div btn-red"
          [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(34) === -1 }"
          (click)="rejectRequest()"
        >
          Reject request
        </div>
        <div
          class="btn-div btn-light-blue"
          [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(32) === -1 }"
          (click)="returnOrder()"
        >
          Return order
        </div>
      </div>
      <div class="btn-col">
        <div
          class="btn-div btn-blue"
          [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(33) === -1 }"
          (click)="deliverLater()"
        >
          Deliver later
        </div>
        <div
          class="btn-div btn-dark-blue"
          [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(31) === -1 }"
          (click)="removeAmount()"
        >
          Remove amount
        </div>
      </div>
    </div>
  </div>
</div>
