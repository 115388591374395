<div class="warning-dialog-container">
  <img src="assets/images/dialogs/delete.svg" />
  <p>{{ data.message }}</p>
  <div>
    <button mat-stroked-button color="warn" (click)="cancel()">
      {{ data.cancelText ? data.cancelText : 'Cancel' }}
    </button>
    <button mat-raised-button color="primary" (click)="proceed()">
      {{ data.actionText ? data.actionText : 'Delete' }}
    </button>
  </div>
</div>
