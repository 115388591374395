import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageKey } from '@app/@core/services/storage/storage-key';
import { StorageService } from '@app/@core/services/storage/storage.service';
import { SupportConfigService } from '@app/@core/services/support-config.service';
import { ContactInfo } from '@app/models/contact-info.model';
import { DomainInfo } from '@app/models/domain-info.model';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';
import { CountriesService } from '@app/@core/services/countries.service';
@Component({
  selector: 'app-company-under-approval',
  templateUrl: './company-under-approval.component.html',
  styleUrls: ['./company-under-approval.component.scss'],
})
export class CompanyUnderApprovalComponent implements OnInit {
  env = environment;
  isAccepted: boolean = true;
  country = CountriesService.countrySettings.country;
  contactInfo: ContactInfo;
  companyLogoUrl: string = '/assets/icons/iot-logo.png';
  isLoading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private supportConfigService: SupportConfigService
  ) {}

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('status');
    this.isAccepted = param !== 'rejected';
    this.getSupportConfigInfo();
    this.setCompanyDetails();
  }

  getSupportConfigInfo() {
    this.supportConfigService
      .getSupportConfig()
      .pipe(take(1))
      .subscribe((response: ContactInfo) => {
        this.contactInfo = response;
      });
  }

  setCompanyDetails() {
    this.isLoading = true;
    const domainInfo: DomainInfo = this.storageService.getData(StorageKey.domainInfo);
    if (domainInfo?.isValidDomain) {
      this.companyLogoUrl = domainInfo.companyLogoUrl || this.companyLogoUrl;
      this.env.solutionOwner = domainInfo.companyName || this.env.solutionOwner;
    }
    this.isLoading = false;
  }
}
