import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const routes = {
  getlocations: () => '/org/merchant/PickupLocation/All',
  getlocationDetails: (id: number) => `/org/merchant/PickupLocation/Details?locationId=${id}`,
  addlocation: () => '/org/merchant/PickupLocation/Add',
  updatelocation: () => '/org/merchant/PickupLocation/Update',
  removelocation: () => '/org/merchant/PickupLocation/Remove',
  addOrUpdateWorkingHour: () => '/org/merchant/PickupLocation/AddOrUpdateWorkingHour',
};

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private httpClient: HttpClient, private credentialsService: CredentialsService) {}

  getLocations(): Observable<string> {
    return this.httpClient
      .get(routes.getlocations(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(
        map((body: any) => body)
        // catchError(() => of('Couldn\'t load locations :('))
      );
  }

  getlocationDetails(locationId: number): Observable<any> {
    return this.httpClient.get(routes.getlocationDetails(locationId), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  addLocation(data: any): Observable<any> {
    return this.httpClient.post(routes.addlocation(), data, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  updateLocation(data: any): Observable<any> {
    return this.httpClient.put(routes.updatelocation(), data, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  removeLocation(data: any): Observable<any> {
    return this.httpClient.put(routes.removelocation(), data, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  addOrUpdateWorkingHour(data: any) {
    return this.httpClient.post(routes.addOrUpdateWorkingHour(), data, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }
}
