import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { TermsPopupComponent } from './terms-popup/terms-popup.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DomainInfo } from './models/domain-info.model';
import { map, take, tap } from 'rxjs/operators';
import { StorageService } from './@core/services/storage/storage.service';
import { StorageKey } from './@core/services/storage/storage-key';
import { CountriesService } from './@core/services/countries.service';
import { forkJoin } from 'rxjs';
import { CitiesService } from './@core/services/cities.service';

export function initializeApp(httpClient: HttpClient, storageService: StorageService) {
  if (!environment.featuresAvailability.domainValidation) return;

  return () =>
    httpClient
      .get<DomainInfo>(environment.serverUrl + '/vendor/domain-info')
      .pipe(take(1))
      .subscribe((response: DomainInfo) => {
        if (response?.isValidDomain && response?.features?.MerchantPortal) {
          storageService.setData(StorageKey.domainInfo, response);
        } else if (location.hostname !== 'localhost') {
          window.open(environment.signupURL, '_self');
        }
      });
}

@NgModule({
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, environment.appName),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    LottieAnimationViewModule.forRoot(),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    AuthModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgMaterialMultilevelMenuModule,
    Angulartics2Module.forRoot(),
    SweetAlert2Module.forRoot(),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route,
  ],
  declarations: [AppComponent, TermsPopupComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient, StorageService, CountriesService, CitiesService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
