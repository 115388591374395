import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoreService } from '@app/@core/services/core.service';
import { GlobalEventsService } from '@app/global-events.service';
import { OptInConfirmationComponent } from '@app/payment-settings/opt-in-confirmation/opt-in-confirmation.component';
import { QuickActionsService } from '@app/quick-action/quick-actions.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pay-by-card',
  templateUrl: './pay-by-card.component.html',
  styleUrls: ['./pay-by-card.component.scss'],
})
export class PayByCardComponent implements OnInit {
  isLoading = false;

  @Input() order: any;

  action: any;

  constructor(
    private quickActionsService: QuickActionsService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private coreService: CoreService,
    private globalEventsService: GlobalEventsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const activeActions: any[] = this.order.quickActions?.filter((action: any) => {
      return action.statusId === 1;
    });
    this.action = activeActions && activeActions.length > 0 ? activeActions[0] : null;
  }

  rejectRequest() {
    this.respond(false);
  }

  allowCard() {
    this.respond(true);
  }

  showCardEnrollmentDialog() {
    const dialog = this.dialog.open(OptInConfirmationComponent, {
      position: {
        bottom: '0',
      },
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'custom-dialog',
      data: {},
    });

    dialog.afterClosed().subscribe((result: any) => {
      if (!result || !result.enabled) {
      }
    });
  }

  private respond(approve: boolean) {
    const body = {
      quickActionId: this.action.quickActionId,
      approve,
    };
    this.isLoading = true;
    this.quickActionsService
      .allowCardPayment(body)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.globalEventsService.onQuickActionResolved.emit(this.action);
        })
      )
      .subscribe(
        (res: any) => {},
        (err: any) => {
          this.coreService.showErrorDialog(err);
        }
      );
  }
}
