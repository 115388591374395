import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickActionBarWrapperComponent } from './quick-action-bar-wrapper/quick-action-bar-wrapper.component';
import { CustomerPaidActionComponent } from './actions/customer-paid-action/customer-paid-action.component';
import { CustomerCantPayActionComponent } from './actions/customer-cant-pay-action/customer-cant-pay-action.component';
import { ActionsListComponent } from './actions-list/actions-list.component';
import { PaymentIsWrongComponent } from './actions/payment-is-wrong/payment-is-wrong.component';
import { UpdateAmountComponent } from './actions/dialogs/update-amount/update-amount.component';
import { PayByCardComponent } from './actions/pay-by-card/pay-by-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MaterialModule } from '@app/material.module';
import { UpdateBoxesNumberComponent } from './actions/update-boxes-number/update-boxes-number.component';

@NgModule({
  declarations: [
    QuickActionBarWrapperComponent,
    CustomerPaidActionComponent,
    CustomerCantPayActionComponent,
    ActionsListComponent,
    PaymentIsWrongComponent,
    UpdateAmountComponent,
    PayByCardComponent,
    UpdateBoxesNumberComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MaterialModule,
  ],
  exports: [QuickActionBarWrapperComponent, ActionsListComponent],
})
export class QuickActionModule {}
