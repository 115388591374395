import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsEvent } from '@app/analytics.service';
import { keys } from 'lodash';
import Swal from 'sweetalert2';

export interface Action {
  name: string;
  emit: () => void;
}
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  onAnalyticsLog = new EventEmitter<any>();
  errorMsg = '';
  errorCode: any;
  constructor(private router: Router, private dialog: MatDialog) {}

  showErrorDialog(msg: any, action: Action = null) {
    const data = {
      action: action,
      error: msg,
      errorCodes: {
        41: {
          img: '/assets/images/images/dialogs/trial-period-error.png',
          btnText: 'ENROLL TO A PLAN',
          title: 'You are on a trial!',
          msg: 'You cannot take this action because you are on a trial period <br> Enroll to a plan to access more options.',
          errorObject: msg.error,
          action: (responseObject: any = null) => {
            this.router.navigateByUrl('/settings?tab=1');
          },
        },
        42: {
          img: '/assets/images/images/dialogs/trial-period-error.png',
          btnText: 'ENROLL TO A PLAN',
          title: 'Your trial period has expired!',
          msg: 'You cannot take this action because your trial period has expired <br> Enroll to a plan to access more options.',
          errorObject: msg.error,
          action: (responseObject: any = null) => {
            this.router.navigateByUrl('/settings?tab=1');
          },
        },
        51: {
          img: '/assets/images/images/sop/delete-sop.svg',
          btnText: 'Navigate To The Existed SOP ',
          title: `<b>SOP Already Existed! </b>`,
          msg: `${msg?.error?.error}`,
          errorObject: msg.error,
          action: (responseObject: any = null) => {
            if (responseObject?.existingSopId)
              this.router.navigate([`/sop/${responseObject?.groupId}`], {
                state: { data: responseObject?.existingSopId },
              });
            this.dialog.closeAll();
          },
        },
      },
    };
    const errorRes = this.errorHandler(data);
    if (errorRes) {
      Swal.fire({
        title: errorRes?.errorObject ? errorRes.title : 'Error',
        html: errorRes?.errorObject ? errorRes.msg : errorRes,
        icon: !errorRes?.errorObject ? 'error' : null,
        imageUrl: errorRes?.errorObject ? errorRes.img : null,
        confirmButtonColor: '#242f5a',
        confirmButtonText: data.action ? data.action.name : errorRes?.errorObject ? errorRes.btnText : 'Ok',
        showCancelButton: errorRes?.errorObject ? true : false,
        cancelButtonText: errorRes?.errorObject ? 'GO BACK' : 'Cancel',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (errorRes?.errorObject) {
            errorRes.action(errorRes?.errorObject?.responseObject);
          } else {
            this.action(data);
          }
        }
      });
    }
  }

  errorHandler(data: any) {
    if (data instanceof String) {
      this.errorMsg = data.toString();
      return this.errorMsg;
    }
    if (data instanceof Object) {
      const error = data.error;
      const errorCodes = data?.errorCodes;
      if (error) {
        if (error instanceof Object) {
          if (error?.error?.code && keys(errorCodes).includes(error?.error?.code.toString())) {
            return errorCodes[error?.error?.code];
          } else if (error.error && error.error.error) {
            this.errorMsg = error.error.error;
            return this.errorMsg;
          } else {
            this.errorMsg = JSON.stringify(error.error);
            return this.errorMsg;
          }
        } else {
          this.errorMsg = data.error;
          return this.errorMsg;
        }
      } else {
        this.errorMsg = JSON.stringify(data);
        return this.errorMsg;
      }
    }
    const analyticsEvent: AnalyticsEvent = {
      eventName: 'dialog_error',
      data: {
        error_message: this.errorMsg,
      },
    };
    this.onAnalyticsLog.emit(analyticsEvent);
  }

  action(data: any) {
    if (data && data.action && data.action.emit) {
      data.action.emit();
    }
  }
}
