import { Observable, Subscription } from 'rxjs';

/**
 * Subscription Manager Class
 * usage: automate unsubscribing from subscriptions of all the components
 */
export class SubscriptionManager {
  // track all the added subscriptions to be unsubscribed from when needed.
  private _subscriptions: Subscription[] = [];

  /**
   * add subscription that will need to be destroyed later on.
   * @param subscription a new subscription that will be added to current class
   */
  add(subscription: Subscription) {
    this._subscriptions.push(subscription);
  }

  /**
   * unsubscribe from all current subscriptions
   */
  destroy() {
    this._subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
