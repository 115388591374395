<div>
  <div mat-dialog-title>RESET PASSWORD</div>
  <div mat-dialog-content class="d-flex flex-column align-content-center">
    <form action="" class="px-3 w-100" [formGroup]="form">
      <mat-form-field class="px-0 d-flex flex-column" appearance="outline">
        <mat-label for="email" class="required">Email</mat-label>
        <input
          matInput
          id="email"
          type="email"
          placeholder="E.g. john@org.com"
          class="col-12"
          formControlName="emailAddress"
        />
      </mat-form-field>
    </form>

    <div class="d-flex flex-auto justify-content-between w-100 px-3">
      <button mat-flat-button color="warn" (click)="onClose()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="!form.valid || loading" (click)="submit()">Submit</button>
    </div>
  </div>
</div>
