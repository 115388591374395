import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { GlobalEventsService } from '@app/global-events.service';
import { AnalyticsEvent } from '@app/analytics.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private globalEventsService: GlobalEventsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(request, error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(request: any, response: any): Observable<HttpEvent<any>> {
    if (response.status === 451) {
      this.globalEventsService.onPromptToAcceptTerms.next();
    }
    if (!environment.production) {
      log.error('Request error', response);
    }

    if (response.status === 403) {
      this.globalEventsService.onUnauthorizedException.emit();
    }

    const analyticsEvent: AnalyticsEvent = {
      eventName: 'http_error',
      data: { request, response },
    };

    this.globalEventsService.onAPIInterceptorError.emit(analyticsEvent);
    if (response.status === 401) {
      let error = {
        message: 'Unauthorized access!',
      };
      this.globalEventsService.onUnauthorizedException.emit(error);
    }

    return throwError(response);
  }
}
