<div>
  <div class="row bar">
    <div class="col-sm-10">
      <div class="row bar-body">
        <div class="col-sm-1">
          <img src="/assets/images/actions/payment.svg" />
        </div>
        <div class="col-sm-8 action-body">
          <h3>CUSTOMER WANTS TO PAY BY CARD.</h3>
          <p class="attention-required-p">
            <span class="red-span">Action is Required!</span>
            for order
            <span class="bold-span">(REF: #{{ order?.companyOrderTrackId }}, id: {{ order?.orderId }})</span>
          </p>
        </div>
        <div class="col-sm-3 right-part">
          <p class="small-title">expires at</p>
          <p>{{ action?.expiryTime | date: 'h:mm a' }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-2 btns">
      <div
        class="btn-div btn-red"
        [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(42) === -1 }"
        (click)="rejectRequest()"
      >
        Reject request
      </div>
      <div
        class="btn-div btn-dark-blue"
        [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(41) === -1 }"
        (click)="allowCard()"
      >
        Allow card payment
      </div>
    </div>
  </div>
</div>
