import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shell-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() image: string;
  @Input() smallText: string;
  @Input() boldText: string;

  constructor() {}

  ngOnInit(): void {}
}
