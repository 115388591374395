import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CoreService } from '@app/@core/services/core.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent implements OnInit {
  visible: boolean = true;
  loading: boolean = false;
  form: FormGroup = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private coreService: CoreService,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>
  ) {}

  ngOnInit(): void {
    this.dialogRef.afterClosed();
  }

  submit() {
    this.loading = true;
    this.authService
      .resetPassword(this.form.getRawValue())
      .pipe(finalize(() => (this.loading = true)))
      .subscribe(
        () => {
          this.coreService.showSuccessDialog(
            'Reset Password Email Sent !',
            'You can now set a new password by clicking the link sent to your email'
          );
          this.onClose();
        },
        (err) => {
          this.coreService.showErrorDialog(err);
        }
      );
  }

  onClose() {
    this.dialogRef.close();
    this.router.navigate([`auth`]);
  }
}
