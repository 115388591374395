<div class="container-fluid">
  <div class="row m-row">
    <div class="col-lg-5 right-panel">
      <div *ngIf="!isCompanyLoading">
        <img width="100px" class="logo-img" [src]="companyLogoUrl" />
        <p class="title">Discover {{ env.solutionOwner }} logistics services in {{ country }}</p>
      </div>
      <div class="sm-line"></div>
      <p class="sign-in-p">Register to the future of logistics</p>
      <img class="left-intro" src="/assets/images/ahoy-intro.svg" />
    </div>
    <div class="col-lg-7 main-content">
      <div>
        <p class="ahoy-link">Already have an account?<a href="/login">Login</a></p>
      </div>

      <app-register-basic-info [hidden]="step !== 1" (proceed)="proceed($event)"></app-register-basic-info>
      <app-verify-otp [hidden]="step !== 2" [accountData]="accountData" (back)="back(1)"></app-verify-otp>
    </div>
  </div>
</div>
