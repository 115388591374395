<div class="form-div custom-card">
  <h3>Please verify your phone number</h3>
  <p class="sub-label">OTP will be sent to the selected number</p>

  <form [formGroup]="verifyOtpForm" novalidate>
    <div class="login-error" [hidden]="!error || isLoading" translate>OTP code is incorrect.</div>
    <div class="login-fields text-center" fxLayout="column">
      <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
      <br />
      <button
        mat-raised-button
        color="primary"
        (click)="verifyOTP()"
        type="submit"
        [disabled]="!isOtpValid() || isLoading"
      >
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <span translate>Verify OTP</span>
      </button>

      <p class="forgot-password">
        Didn't receive the OTP yet?
        <a class="resend-otp-link" (click)="resendOtp()">Resend OTP?</a>
      </p>
      <p class="forgot-password">
        Change phone number
        <a class="resend-otp-link" (click)="goBack()">Go Back</a>
      </p>
    </div>
  </form>
</div>
