<div>
  <h3 class="news-title">What's New</h3>
  <img class="news-logo" src="/assets/images/calls.svg" />
  <p class="news-subtitle">Cloud Telephony</p>
  <p class="news-description">
    Cloud Telephony will be your way to have access to communications logs between your customers and the drivers, set
    your preferences on how we handle the contact between them and many more things. All this, coming soon
  </p>
  <button mat-flat-button color="primary" (click)="navigateTo('/settings?tab=6')" class="get-started-btn">
    See more
  </button>
</div>
