import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from '@app/auth';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { GlobalEventsService } from '@app/global-events.service';
import { environment } from '@env/environment';
import { LocationService } from '@app/location/location.service';
import { Option } from './settings.types.service';

const routes = {
  allPlans: () => `/org/transactions/Merchant/AllPlans`,
  enrollToPlan: () => '/org/transactions/Merchant/EnrollToPlan',
  getCurrentPlan: () => '/org/transactions/Merchant/CurrentPlan',
  updateReturnPolicy: () => '/org/merchant/Merchant/CreateReturnPolicy',
  getReturnPolicy: () => '/org/merchant/Merchant/ReturnPolicy',
  getAllPermissions: () => `/org/merchant/Merchant/AllPermissions`,
  getPaymentConfigs: () => '/org/transactions/merchant/payment-configurations',
  getPaymentPreferences: () => `/org/transactions/PaymentPreference/Get`,
  setPaymentPreferences: () => `/org/transactions/PaymentPreference/Set`,
  shouldShowFridayBanner: () => `/org/merchant/Merchant/Banner`,
  generateWebhookKeys: () => `/org/delivery/ecommercewebhooks/create`,
  getWebhookKeys: () => `/org/delivery/ecommercewebhooks/get`,
  getCompanyRoles: () => '/org/merchant/company/roles',
  getCompanyUsers: () => '/org/merchant/company/users',
  updateUserRoles: () => '/org/merchant/company/userroles',
  createNewUser: () => '/org/merchant/Company/CreateNewUser',
  deleteUnRegisteredUser: () => '/org/merchant/Company/DeleteUnRegisteredUser',
  togglePaymentOption: () => '/org/transactions/toggle-payment-option',
  getVendorInfo: () => '/org/merchant/vendor/vendor-info',
};

export interface Plan {
  planId: number;
  price: number;
  imageUrl: string;
  planTitle: string;
  planUrl: string;
  slogan: string;
  currency: string;
  validityPeriodText: string;
  planDetails: string[];
  selected: boolean;
  endDate: number;
  nextBillingDate: number;
  startDate: number;
}

export enum PaymentOptions {
  CASH = 1,
  CARD = 2,
  ONLINE_PAYMENT = 3,
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private settings: any = {};

  constructor(
    private httpClient: HttpClient,
    private credentialsService: CredentialsService,
    private locationService: LocationService,
    globalEventsService: GlobalEventsService
  ) {
    globalEventsService.onLoadCompanyPlan.subscribe(() => {
      this.loadCompanyCurrentPlan();
      this.loadCompanySettings();
      this.loadPaymentConfigs();
    });
  }

  shouldShowFridayBanner() {
    const req = this.httpClient
      .get(routes.shouldShowFridayBanner(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(shareReplay());

    return req;
  }

  private loadCompanySettings(): Observable<any> {
    const req = this.httpClient
      .get(routes.getAllPermissions(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(shareReplay());

    req.subscribe((res) => {
      this.settings.permissions = res;
    });

    return req;
  }

  private loadPaymentConfigs(): Observable<any> {
    const req = this.httpClient
      .get<any>(routes.getPaymentConfigs(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(shareReplay());

    req.subscribe((res) => {
      this.settings.paymentConfigurations = res;
    });

    return req;
  }

  generateWebhookKeys(): Observable<any> {
    return this.httpClient.post(
      routes.generateWebhookKeys(),
      {},
      {
        headers: new HttpHeaders({
          Authorization: `bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }

  getWebhookKeys(): Observable<any> {
    return this.httpClient.get(routes.getWebhookKeys(), {
      headers: new HttpHeaders({
        Authorization: `bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  loadPermissions(): Observable<any> {
    return this.loadCompanySettings();
  }

  togglePaymentOption(body: any) {
    return this.httpClient.post(routes.togglePaymentOption(), body, {
      headers: new HttpHeaders({
        Authorization: `bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  paymentOptions(cached: boolean = true): Observable<any> {
    if (cached && this.settings?.paymentConfigurations) {
      return of(this.settings.paymentConfigurations?.paymentOptions);
    }
    return this.loadPaymentConfigs().pipe(map((res) => res?.paymentOptions));
  }

  isCashAllowed(): Observable<boolean> {
    if (this.settings?.permissions) {
      return of(this.settings.permissions.enableCashPayment);
    }
    return this.loadPermissions().pipe(map((res) => res?.enableCashPayment));
  }

  paymentPreference(): Observable<number> {
    if (this.settings?.paymentConfigurations) {
      return of(this.settings.paymentConfigurations?.paymentPreferenceId);
    }
    return this.loadPaymentConfigs().pipe(map((res) => res?.paymentPreferenceId));
  }

  setPaymentPreference(PaymentPreferenceId: number): Observable<any> {
    if (this.settings.paymentConfigurations) {
      this.settings.paymentConfigurations.paymentPreferenceId = PaymentPreferenceId;
    }
    return this.httpClient.post(
      routes.setPaymentPreferences(),
      {
        PaymentPreferenceId,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }

  getPaymentPreferences(): Observable<any> {
    return this.httpClient.get(routes.getPaymentPreferences(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  isEnrolledInCardPayment(cached: boolean = true): Observable<boolean> {
    return this.paymentOptions(cached).pipe(map((res) => res.find((option: Option) => option.id === 2).optedIn));
  }

  isBulkOrderEnabled(): Observable<boolean> {
    if (this.settings?.permissions) {
      return of(this.settings.permissions.bulkCreationDisabled);
    }
    return this.loadPermissions().pipe(map((res) => res?.bulkCreationDisabled));
  }

  loadCompanyCurrentPlan(): Observable<any> {
    const request = this.httpClient
      .get(routes.getCurrentPlan(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(shareReplay());

    request.subscribe(
      (res) => {
        this.settings.currentPlan = res;
      },
      (err) => {}
    );

    return request;
  }

  isAllowedToCreateOrder(): Observable<boolean> {
    return combineLatest([this.getCurrentCompanyPlan(), this.isInTrialPeriod()]).pipe(
      map((res) => res[0] !== null || res[1])
    );
  }

  getTrialInfo(): Observable<any> {
    return this.getAllPlans().pipe(map((res) => res?.trialInfo));
  }

  isInTrialPeriod(): Observable<any> {
    return this.getAllPlans().pipe(map((res) => res?.trialInfo !== null && res?.trialInfo?.isActive !== false));
  }

  isPickupLocationAdded(): Observable<boolean> {
    return this.locationService
      .getLocations()
      .pipe(map((res) => res?.length !== null && res?.length !== undefined && res?.length !== 0));
  }

  getCurrentCompanyPlan(): Observable<number> {
    if (this.settings.currentPlan) {
      return of(this.settings.currentPlan);
    }
    // if it is not there that mean somehow the plan wasn't loaded
    return this.loadCompanyCurrentPlan();
  }

  isEnrolledInPlan(): Observable<boolean> {
    return this.getAllPlans().pipe(map((res) => res.currentActivePlan !== null));
  }

  getCompanyProofOfDeliveryPrice(): Observable<number> {
    if (this.settings.currentPlan) {
      return of(this.settings.currentPlan.proofOfDeliveryCharge);
    }
    // if it is not there that mean somehow the plan wasn't loaded
    return this.loadCompanyCurrentPlan().pipe(map((res) => res?.proofOfDeliveryCharge));
  }

  getAllPlans(): Observable<any> {
    return this.httpClient
      .get(routes.allPlans(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials?.token}`,
        }),
      })
      .pipe(
        map((plans: any) => {
          return this.mapPlansToActivePlan(plans);
        })
      );
  }

  updateReturnPolicy(policy: any): Observable<any> {
    if (!policy.Id) {
      policy.Id = 0;
    }
    return this.httpClient.put(routes.updateReturnPolicy(), policy, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getReturnPolicy(): Observable<any> {
    return this.httpClient.get(routes.getReturnPolicy(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  mapPlansToActivePlan(plans: any) {
    if (plans.currentActivePlan) {
      for (let i = 0; i < plans.allPlans.length; i++) {
        if (!plans.allPlans[i].imageUrl) {
          plans.allPlans[i].imageUrl = '/assets/images/rocket.svg';
        }
        if (plans.allPlans[i].planId === plans.currentActivePlan.planId) {
          // add current plan date to the plan
          plans.allPlans[i].selected = true;
          plans.allPlans[i].endDate = plans.currentActivePlan.endDate;
          plans.allPlans[i].nextBillingDate = plans.currentActivePlan.nextBillingDate;
          plans.allPlans[i].startDate = plans.currentActivePlan.startDate;

          // add the plan details to current active plan
          plans.currentActivePlan.title = plans.allPlans[i].planTitle;
          plans.currentActivePlan.slogan = plans.allPlans[i].slogan;
          plans.currentActivePlan.imageUrl = plans.allPlans[i].imageUrl;
          plans.currentActivePlan.cardCODFeesPercentage = plans.allPlans[i].cardCODFeesPercentage;
        }
      }
    }
    return plans;
  }

  enrollToPlan(plan: Plan): Observable<any> {
    return this.httpClient
      .post(
        routes.enrollToPlan(),
        {
          PlanId: plan.planId,
        },
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.credentialsService.credentials.token}`,
          }),
        }
      )
      .pipe(
        map((plans: any) => {
          return this.mapPlansToActivePlan(plans);
        })
      );
  }

  getCompanyRoles(): Observable<any> {
    return this.httpClient.get(routes.getCompanyRoles(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getCompanyUsers(): Observable<any> {
    return this.httpClient.get(routes.getCompanyUsers(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  updateUserRoles(data: any): Observable<any> {
    return this.httpClient.put(routes.updateUserRoles(), data, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createNewUser(user: any): Observable<any> {
    return this.httpClient.post(routes.createNewUser(), user, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  deleteUnRegisteredUser(user: any): Observable<any> {
    return this.httpClient.request('delete', routes.deleteUnRegisteredUser(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
      body: {
        phone: user.phone,
        email: user.email,
      },
    });
  }
}
