import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from '@app/@core/services/core.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-company-documents',
  templateUrl: './company-documents.component.html',
  styleUrls: ['./company-documents.component.scss'],
})
export class CompanyDocumentsComponent implements OnInit {
  @Output('proceed') proceed = new EventEmitter<any>();

  error: string | undefined;

  isLoading = false;

  vat: File = null;
  tradeLicense: File = null;

  @Input() companyData: any;

  constructor(private authService: AuthenticationService, private router: Router, private coreService: CoreService) {}

  ngOnInit(): void {}

  onVatSelected(e: any) {
    this.vat = e.target.files[0];
  }

  onTradeLicenseSelected(e: any) {
    this.tradeLicense = e.target.files[0];
  }

  create() {
    if (this.companyData && this.vat && this.tradeLicense) {
      this.isLoading = true;
      this.authService
        .createCompany(this.companyData, this.vat, this.tradeLicense)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (res) => {
            this.router.navigateByUrl('/companyReview');
          },
          (err) => {
            if (err.error.code !== 4) {
              this.coreService.showErrorDialog(err);
            }
          }
        );
    }
  }
}
