import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './loader/loader.component';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { MapComponent } from './map/map.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { CounterComponent } from './counter/counter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './shell/card/card.component';
import { SelectEcommerceDeliveryMethodComponent } from './dialogs/select-ecommerce-delivery-method/select-ecommerce-delivery-method.component';
import { WarningDialogComponent } from './dialogs/warning-dialog/warning-dialog.component';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { SuccessDialogComponent } from './dialogs/success-dialog/success-dialog.component';
import { CreationSuccessDialogComponent } from './creation-success-dialog/creation-success-dialog.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SafePipe } from '@app/sanitier.pipe';

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
  ],
  declarations: [
    LoaderComponent,
    ErrorPopupComponent,
    MapComponent,
    DeleteConfirmationComponent,
    BackButtonComponent,
    CounterComponent,
    CardComponent,
    SelectEcommerceDeliveryMethodComponent,
    WarningDialogComponent,
    DeleteDialogComponent,
    SuccessDialogComponent,
    CreationSuccessDialogComponent,
    SafePipe,
  ],
  exports: [
    LoaderComponent,
    MapComponent,
    BackButtonComponent,
    CounterComponent,
    CardComponent,
    SelectEcommerceDeliveryMethodComponent,
    SafePipe,
  ],
})
export class SharedModule {}
