import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionsService } from '@app/settings/permissions/permissions.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private permissionsService: PermissionsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.permissionsService.hasAccessToCOD() && route.url[0].path === 'finance') {
      return true;
    }

    if (this.permissionsService.hasAccessToInvoices() && route.url[0].path === 'invoice') {
      return true;
    }

    this.router.navigate(['/home'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
