<div>
  <h3>Select Service</h3>
  <p>Select which delivery service you want to use</p>
  <div class="row">
    <div class="col-lg-6">
      <div class="delivery-item" [ngClass]="{ selected: selectedItem === 0 }" (click)="selectedItem = 0">
        <img *ngIf="selectedItem === 0" class="radio" src="/assets/images/ecommerce/radio-selected.png" />
        <img *ngIf="selectedItem !== 0" class="radio" src="/assets/images/ecommerce/radio-empty.png" />
        <img class="logo" src="/assets/images/ecommerce/ahoy-network.png" />
        <h5>Request Delivery</h5>
        <p>Deliver your order by {{ env.solutionOwner }} networking</p>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="delivery-item" [ngClass]="{ selected: selectedItem === 1 }" (click)="selectedItem = 1">
        <img *ngIf="selectedItem === 1" class="radio" src="/assets/images/ecommerce/radio-selected.png" />
        <img *ngIf="selectedItem !== 1" class="radio" src="/assets/images/ecommerce/radio-empty.png" />
        <img class="logo" src="/assets/images/ecommerce/priority-delivery.png" />
        <h5>Priority Delivery</h5>
        <p>Prioritise this order and deliver as fast as possible</p>
      </div>
    </div>
  </div>
  <button mat-flat-button color="primary" (click)="proceed()">Proceed</button>
</div>
