import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  currentUserAccess: any;
  constructor(private authenticationService: AuthenticationService) {}

  // check if current user have access to view invoices
  hasAccessToInvoices() {
    this.currentUserAccess = this.authenticationService.getUserAccess();
    const hasAccessToInvoices = this.currentUserAccess?.assignedRoles?.some(
      (r: any) => (r.name = 'Administrator' || 'Financial Manager')
    );
    return hasAccessToInvoices;
  }

  // check if current user have access to view COD
  hasAccessToCOD() {
    this.currentUserAccess = this.authenticationService.getUserAccess();
    const hasAccessToCOD = this.currentUserAccess?.assignedRoles?.some(
      (r: any) => (r.name = 'Administrator' || 'COD Manager')
    );
    return hasAccessToCOD;
  }
}
